






import { Component, Vue } from 'vue-property-decorator'

import StudyRulesTable from '@/partials/tables/Events/StudyRules/index.vue'

@Component({
  components: {
    StudyRulesTable
  }
})
export default class StudyRulesIndex extends Vue {

}
