
























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import isEmpty from 'lodash/isEmpty'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    GenericModalDelete,
    TrashCan16,
    Ticket16,
    Edit16,
    TablePagination
  }
})
export default class EventStudyRulesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  rules = []
  filteredData = []
  deleteModalIsOpened = false
  selectedStudyRule = {
    id: '',
    title: ''
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study-rules`)
      .then(response => {
        this.rules = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Regra foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  get deleteUrl () {
    return isEmpty(this.selectedStudyRule) ? '' : `/event/${this.eventId}/study-rules/${this.selectedStudyRule.id}`
  }
}
